import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterBar = _resolveComponent("FilterBar")!
  const _component_WineCard = _resolveComponent("WineCard")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PageFooter = _resolveComponent("PageFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-start" }, " Assortiment ", -1)),
    ($setup.wines.length > 0)
      ? (_openBlock(), _createBlock(_component_FilterBar, {
          key: 0,
          count: $setup.total,
          filter: $setup.filter,
          onReset: $setup.onFilterReset,
          onFilter: $setup.onFilterChange,
          onSort: _cache[0] || (_cache[0] = ($event: any) => ($setup.getWines()))
        }, null, 8, ["count", "filter", "onReset", "onFilter"]))
      : _createCommentVNode("", true),
    ($setup.wines.length > 0)
      ? (_openBlock(), _createBlock(_component_Table, { key: 1 }, {
          items: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.wines, (wine) => {
              return (_openBlock(), _createBlock(_component_WineCard, {
                key: wine.id,
                wine: wine,
                onWineClick: ($event: any) => ($setup.openWine(wine))
              }, null, 8, ["wine", "onWineClick"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($setup.wines.length > 0 && $setup.total - $setup.currentCount !== 0)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 2,
          text: $setup.buttonText,
          design: "primary",
          onButtonClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.loadMore()))
        }, null, 8, ["text"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PageFooter, {
      footer: "Eerdere bestellingen herhalen?",
      button: $setup.button,
      font: "dinpro",
      box: "Dit kan in uw eigen account. Ga naar bestellingen om een eerdere bestelling te herhalen.",
      onButtonClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.button.callBack()))
    }, null, 8, ["button"])
  ]))
}