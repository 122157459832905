
import { plainToClass } from 'class-transformer';
import {
    computed, onMounted, reactive, ref, watch,
} from 'vue';
import {
    onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter, 
} from 'vue-router';
import { useStore } from 'vuex';
import FilterBar from '@/components/Filter/FilterBar.vue';
import WineCard from '@/components/WineCard.vue';
import Button from '@/shared/components/Button.vue';
import PageFooter from '@/shared/components/PageFooter.vue';
import Table from '@/shared/components/Table.vue';
import { Relation, Wine } from '@/shared/models/Wine';

export class Query {
    land?: string;
    wijnhuis?: string;
    search?: string;
}

export default {
    components: {
        PageFooter,
        FilterBar,
        Table,
        WineCard,
        Button,
    },
    setup() {
        const wines = ref(Array<Wine>());
        const filter = ref({
            countries: Array<Relation>(),
            estates: Array<Relation>(),
        });
        const store = useStore();
        const currentPage = ref(1);
        const total = ref(0);
        const currentCount = computed(() => wines.value.length);
        const route = useRoute();
        const routeState = reactive(route);
        const router = useRouter();
        const query = ref(new Query());
        const computedSearch = computed(() => store.getters.isSearching());
        const searchString = computed(() => store.getters.getSearch());
        function getWines(reset?: boolean) {
            currentPage.value = 1;
            if (reset) {
                store.dispatch('SET_SEARCH', '');
                router.push({ name: 'Assortiment', query: { } });
            }

            return new Promise((resolve) => {
                store.dispatch('WINES_GET', { currentPage, search: searchString.value }).then((res) => {
                    filter.value = res.data;
                    wines.value = plainToClass(Wine, res.data.wines as Wine[]);
                    total.value = res.data.metadata.total;
                    resolve(res.data);
                });
            });
        }

        watch(computedSearch, () => {
            if (computedSearch.value) {
                store.commit('DO_SEARCH');
                getWines();
            }
        }, {
          immediate: true,
        });
        
        function checkRouteFilter() {
            if (query.value.search) {
                return;
            }

            store.dispatch('WINES_GET_ALL').then((res) => {
                filter.value = res.data;
                
                if (query.value.land) {
                    const found = filter.value.countries.find((item: Relation) => item.name.toLowerCase() === query.value.land);
                    if (found) {
                        store.commit('SET_ASSORTMENT_FILTER', `country=${found.id}`);
                    }
                }

                if (routeState.query.wijnhuis) {
                    const found = filter.value.estates.find((item: Relation) => item.name === query.value.wijnhuis);
                    if (found) {
                        store.commit('SET_ASSORTMENT_FILTER', `estate=${found.id}`);
                    }
                }

                if (query.value.land || routeState.query.wijnhuis) {
                    getWines();
                }
            });
        }

        onMounted(() => {
            query.value = routeState.query;
            if (query.value.search) {
                store.dispatch('SET_SEARCH', query.value.search).then(() => {
                    getWines();
                });
            }
            checkRouteFilter();
        });

        onBeforeRouteUpdate((to, from) => {
            if (to.name === 'Assortiment' && from.name === 'Assortiment') {
                const fromQuery = JSON.stringify(from.query);
                const toQuery = JSON.stringify(to.query);

                if (fromQuery !== toQuery) {
                    query.value = to.query;
                    checkRouteFilter();
                    store.dispatch('SET_SEARCH', query.value.search);
                }
            }
        });

        onBeforeRouteLeave((from, to, next) => {
            // Reset search
            store.dispatch('SET_SEARCH', '');
            next();
        });
        
        function loadMore() {
            currentPage.value += 1;

            store.dispatch('WINES_GET', { currentPage, search: searchString.value }).then((res) => {
                filter.value = res.data;
                res.data.wines.forEach((wine: any) => {
                    wines.value.push(plainToClass(Wine, wine));
                });
                total.value = res.data.metadata.total;
            });
        }

        const buttonText = computed(() => `TOON MEER (${total.value - currentCount.value})`);

        function navigateTo(url: any) {
            window.scrollTo(0, 0);
            router.push(url);
        }

        const button = {
            text: 'Naar bestellingen',
            callBack: () => navigateTo({ name: 'Orders' }),
        };

        function openWine(wine: Wine) {
            const id = String(wine.id);
            window.scrollTo(0, 0);
            router.push({ name: 'AssortimentDetail', params: { id } });
        }

        function onFilterReset(filterReset: string) {
            store.commit('SET_ASSORTMENT_FILTER', filterReset);
            getWines(true);
        }

        function onFilterChange(filterChange: string) {
            store.commit('SET_ASSORTMENT_FILTER', filterChange);
            getWines();
        }

        return {
            button,
            filter,
            wines,
            total,
            currentCount,
            buttonText,
            loadMore,
            openWine,
            getWines,
            computedSearch,
            onFilterReset,
            onFilterChange,
        };
    },
};
